import { Add, Android, Apple, Delete, DeveloperMode, Download, Edit, Microsoft, Public } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box, Button, Chip, Container, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { indigo } from "@mui/material/colors";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFileDownload, useGetFileMetas, useGetFileThumbnail } from "wcz-file";
import { LayoutContext, TypographyWithIcon, hasRole } from "wcz-layout";
import packageJson from "../../package.json";
import { EditAppDialog } from "../components/app/EditAppDialog";
import { CreateReleaseDialog } from "../components/release/CreateReleaseDialog";
import { EditReleaseDialog } from "../components/release/EditReleaseDialog";
import { ReleasesDialog } from "../components/release/ReleasesDialog";
import App from "../models/App";
import Platform from "../models/Platform";
import Release from "../models/Release";
import { AppType } from "../models/enums/AppType";
import { PlatformType } from "../models/enums/PlatformType";
import { useDeleteApp, useGetApp } from "../queries/AppQueries";
import AuthPolicy from "../utils/AuthPolicy";
import { fileUrlNA } from "../utils/BaseUrl";
import { isAndroid, isIphone } from "../utils/Helpers";

export const AppPage: React.FC = () => {
    const { id } = useParams();
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const [platform, setPlatform] = useState<Platform>({} as Platform);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [releasesOpen, setReleasesOpen] = useState<boolean>(false);
    const [addReleaseOpen, setAddReleaseOpen] = useState<boolean>(false);
    const [editReleaseId, setEditReleaseId] = useState<string>("");
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => changeTitle(t("Application")), [i18n.resolvedLanguage]);

    const { data: app = {} as App } = useGetApp(id);

    useEffect(() => {
        initPlatform();
    }, [app]);

    const initPlatform = useCallback(() => {
        if (platform.id) {
            const updatedPlatform = app.platforms?.find(p => p.id === platform.id);
            if (updatedPlatform)
                return setPlatform(updatedPlatform);
        } else {
            const defaultPlatform = app.platforms?.find(p => {
                if (app.type === AppType.Web)
                    return p.type === PlatformType.Web || p.type === PlatformType.PowerApps;

                if (isAndroid)
                    return p.type === PlatformType.Android;

                if (isIphone)
                    return p.type === PlatformType.Iphone;

                return undefined;
            });

            if (defaultPlatform || app.platforms?.length)
                setPlatform(defaultPlatform || app.platforms[0]);
        }
    }, [app, platform]);

    const release = useMemo(() => platform.latestProductionRelease || platform.latestDevelopmentRelease, [platform, editReleaseId]);

    const { data: appLogoMetas = [] } = useGetFileMetas(app.id, {
        enabled: !!app.id
    });

    const { data: appLogoSrc } = useGetFileThumbnail(appLogoMetas[0]);

    const { mutate: downloadFile } = useFileDownload();

    const { data: productionFileMetas } = useGetFileMetas(platform.latestProductionRelease?.value, {
        enabled: app.type === AppType.Application && !!platform.latestProductionRelease?.value
    });

    const { refetch: openProductionPlist } = useGetFileMetas(`${platform.latestProductionRelease?.value}-plist`, {
        enabled: false,
        onSuccess: data => {
            if (data.length) {
                const url = `itms-services://?action=download-manifest&url=${fileUrlNA}/v1/${packageJson.name}/${data[0].id}`;
                window.location.href = url;
            }
        }
    });

    const handleOnProductionClick = useCallback(() => {
        if (app.type === AppType.Web)
            return window.open(platform.latestProductionRelease?.value);

        if (platform.type === PlatformType.Iphone)
            return openProductionPlist();

        if (productionFileMetas?.length)
            return downloadFile(productionFileMetas[0]);
    }, [app, platform, productionFileMetas]);

    const { data: developmentFileMetas } = useGetFileMetas(platform.latestDevelopmentRelease?.value, {
        enabled: app.type === AppType.Application && !!platform.latestDevelopmentRelease?.value
    });

    const { refetch: openDevelopmentPlist } = useGetFileMetas(`${platform.latestDevelopmentRelease?.value}-plist`, {
        enabled: false,
        onSuccess: data => {
            if (data.length) {
                const url = `itms-services://?action=download-manifest&url=${fileUrlNA}/v1/${packageJson.name}/${data[0].id}`;
                window.location.href = url;
            }
        }
    });

    const handleOnDevelopmentClick = useCallback(() => {
        if (app.type === AppType.Web)
            return window.open(platform.latestDevelopmentRelease?.value);

        if (platform.type === PlatformType.Iphone)
            return openDevelopmentPlist();

        if (developmentFileMetas?.length)
            return downloadFile(developmentFileMetas[0]);
    }, [app, platform, developmentFileMetas]);

    const handleOnEditClick = useCallback(() => setEditOpen(true), []);

    const { mutate: deleteApp } = useDeleteApp({
        onSuccess: () => navigate("/")
    });

    const handleOnDeleteClick = useCallback(() => {
        if (window.confirm(t("AreYouSure")))
            deleteApp(app.id);
    }, [app]);

    const handleOpenReleases = useCallback(() => setReleasesOpen(true), []);
    const handleOpenAddRelease = useCallback(() => setAddReleaseOpen(true), []);

    const handleOpenEditRelease = useCallback((release: Release) => () => {
        setReleasesOpen(false);
        setEditReleaseId(release.id);
    }, []);

    const handleSetReleaseId = useCallback((id: string) => {
        setEditReleaseId(id);
        if (!id) setReleasesOpen(true);
    }, []);

    const handlePlatformChange = useCallback((value: Platform) => () => setPlatform(value), []);

    const getPlatformIcon = useCallback((type: PlatformType) => {
        switch (type) {
            case PlatformType.Web:
                return <Public />;
            case PlatformType.Android:
                return <Android />;
            case PlatformType.Iphone:
                return <Apple />;
            case PlatformType.PowerApps:
                return <Microsoft />;
            default:
                return <Public />;
        }
    }, []);

    const hasProduction = useMemo(() => (app.type === AppType.Web && !!platform.latestProductionRelease?.value) || (!!productionFileMetas?.length), [app, platform, productionFileMetas]);
    const hasDevelopment = useMemo(() => (app.type === AppType.Web && !!platform.latestDevelopmentRelease?.value) || (!!developmentFileMetas?.length), [app, platform, developmentFileMetas]);

    return (
        <Container maxWidth="md" sx={{ my: 2 }}>

            <Grid container>
                <Grid item xs={12} mb={1}>
                    <Stack direction="row" spacing={1}>
                        {app.platforms?.map(p => <Chip key={p.id} label={p.type} onClick={handlePlatformChange(p)} size="small" color={p.type === platform.type ? "primary" : "default"}
                            icon={getPlatformIcon(p.type)} />)}
                    </Stack>
                </Grid>

                <Grid item xs={8}>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant={isLargeScreen ? "h4" : "h5"}>{app.title}</Typography>
                            <Grid container spacing={1}>
                                {app.categories?.map(category =>
                                    <Grid item key={category.id}><Typography>{`#${category.name}`}</Typography></Grid>
                                )}
                            </Grid>
                        </Box>

                        {app.description &&
                            <Box>
                                {app.description.split("\n").map((paragraph, index) => <Typography key={index}>{paragraph}</Typography>)}
                            </Box>
                        }

                        {isLargeScreen &&
                            <Stack direction="row" spacing={1}>
                                {hasProduction && <Button variant="contained" onClick={handleOnProductionClick}>{t(app.type === AppType.Web ? "Open" : "Download")}</Button>}
                                {hasDevelopment && <Button startIcon={<DeveloperMode />} onClick={handleOnDevelopmentClick}>{t("TestEnvironment")}</Button>}
                                {hasRole(AuthPolicy.Contributor) && <Button startIcon={<Edit />} onClick={handleOnEditClick}>{t("Edit")}</Button>}
                                {hasRole(AuthPolicy.Contributor) && <Button startIcon={<Delete />} onClick={handleOnDeleteClick}>{t("Delete")}</Button>}
                            </Stack>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <Box src={appLogoSrc} component="img" sx={{ width: isLargeScreen ? 200 : 100, borderRadius: 3 }} />
                </Grid>
            </Grid>

            {release?.id &&
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TypographyWithIcon
                            variant={isLargeScreen ? "h5" : "h6"}
                            endIcon={<Chip size="small" label={platform.releases.length} />}
                            onClick={handleOpenReleases}
                            sx={{ cursor: "pointer", ":hover": { color: indigo[500] } }}
                        >
                            {t("Releases")}
                        </TypographyWithIcon>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Version")}: </b>{release?.version}</Typography>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Environment")}: </b>{t(release?.environment)}</Typography>

                        <Typography color="text.secondary" sx={{ fontSize: 13 }} gutterBottom>
                            <Tooltip title={`${release?.createdBy} ${t("In")} ${moment(release?.releaseDate).formatDateTime()}`}>
                                <span>
                                    <b>{t("DateOfUpdate")}: </b>{moment(release?.releaseDate).formatDate()}
                                </span>
                            </Tooltip>
                        </Typography>

                        {release?.releaseNotes.split("\n").map((paragraph, index) => <Typography key={index}>{paragraph}</Typography>)}
                    </Grid>
                </Grid>
            }

            {hasRole(AuthPolicy.Contributor) && <Button sx={{ mt: 3 }} variant="outlined" startIcon={<Add />} fullWidth onClick={handleOpenAddRelease}>{t("NewRelease")}</Button>}

            {(!isLargeScreen && (hasProduction || hasDevelopment)) &&
                <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation showLabels>
                        {hasDevelopment && <BottomNavigationAction label={t("TestEnvironment")} icon={<DeveloperMode />} onClick={handleOnDevelopmentClick} />}
                        {hasProduction && <BottomNavigationAction label={t(app.type === AppType.Web ? "Open" : "Download")} icon={app.type === AppType.Web ? <Public /> : <Download />} onClick={handleOnProductionClick} />}
                    </BottomNavigation>
                </Paper>
            }

            {id && <EditAppDialog open={editOpen} setOpen={setEditOpen} id={id} />}

            <ReleasesDialog open={releasesOpen} setOpen={setReleasesOpen} platform={platform} handleOnEditClick={handleOpenEditRelease} />
            <CreateReleaseDialog open={addReleaseOpen} setOpen={setAddReleaseOpen} platform={platform} app={app} />
            <EditReleaseDialog id={editReleaseId} setId={handleSetReleaseId} platform={platform} app={app} />
        </Container>
    );
};