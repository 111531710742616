import packageJson from "../../package.json";
import Release from "../models/Release";
import { ReleaseEnvironment } from "../models/enums/ReleaseEnvironment";
import { fileUrlNA } from "./BaseUrl";

export const isAndroid: boolean = /(android)/i.test(navigator.userAgent);
export const isIphone: boolean = /(android|iphone)/i.test(navigator.userAgent);

export const getPlistContent = (fileId: string, release: Release, title: string) =>
  `<plist version="1.0">
   <dict>
      <key>items</key>
      <array>
         <dict>
            <key>assets</key>
            <array>
               <dict>
                  <key>kind</key>
                  <string>software-package</string>
                  <key>url</key>
                  <string>${fileUrlNA}/v1/${packageJson.name}/${fileId}</string>
               </dict>
            </array>
            <key>metadata</key>
            <dict>
               <key>bundle-identifier</key>
                <string>${`com.wistron.wczdeviceinfo${release.environment === ReleaseEnvironment.Development ? "-qas" : ""}`}</string>
                <key>bundle-version</key>
                <string>${release.version}</string>
                <key>kind</key>
                <string>software</string>
               <key>title</key>
               <string>${title}</string>
            </dict>
         </dict>
      </array>
   </dict>
</plist>`;